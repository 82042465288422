import { Script } from 'gatsby';

const OneTrustScript = () => {
  return (
    <>
      <Script
        id="onetrust-sdk"
        key="onetrust-sdk"
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        data-document-language="true"
        type="text/javascript"
        data-domain-script={process.env.ONETRUST_TOKEN}
      />
      <Script
        id="onetrust-script"
        key="onetrust-script"
        src="/oneTrustCookieScript.js"
        type="text/javascript"
      />
    </>
  );
};
export default OneTrustScript;
