import { createContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

const defaultState = {
  client_id: '',
  client_secret: '',
  scope: '',
  channel_token: '',
  'x-pcf-csrf-token': '',
  rates: {
    promoInterestRate: null,
    baseInterestRate: null,
    promoInterestDays: null,
  },
};

const CoreContext = createContext(defaultState);

const Core = ({ children }) => {
  const [core, setCore] = useState(defaultState);

  const updateCore = coreValue => {
    setCore(prevCore => {
      return { ...prevCore, ...coreValue };
    });
  };

  const coreContextValues = useMemo(() => ({ core, updateCore }), [core]);

  return (
    <CoreContext.Provider value={coreContextValues}>
      {children}
    </CoreContext.Provider>
  );
};

Core.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export { Core, CoreContext };
