import { ParallaxProvider } from 'react-scroll-parallax';

import { Provider } from './src/Components/Providers/Blade';
import { Geolocation } from './src/Components/Providers/Geolocation';
import { BannerProvider } from './src/Components/Providers/Banner';
import { SearchProvider } from './src/Components/Providers/SearchIndex';
import { ResponsiveProvider } from './src/Components/Providers/Responsive';
import { Core } from './src/Components/Providers/Core';
import DataLayerConfig from './src/scripts/dataLayerConfig';
import OneTrustScript from './src/scripts/oneTrustScript';

export const wrapRootElement = ({ element }) => (
  <Core>
    <ParallaxProvider>
      <BannerProvider>
        <Geolocation>
          <SearchProvider>
            <ResponsiveProvider>
              <Provider>{element}</Provider>
            </ResponsiveProvider>
          </SearchProvider>
        </Geolocation>
      </BannerProvider>
    </ParallaxProvider>
  </Core>
);

export const wrapPageElement = ({ element }) => {
  return (
    <>
      {element}
      <DataLayerConfig />
      <OneTrustScript />
    </>
  );
};
