import { createContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

const defaultState = {
  notificationBannerState: 'fixed',
  updateNotificationBannerState: () => {},
};

const BannerContext = createContext(defaultState);

const BannerProvider = ({ children }) => {
  const [state, setState] = useState({
    notificationBannerState: 'fixed',
  });

  const updateNotificationBannerState = bannerState => {
    setState(prevState => {
      const newState = { notificationBannerState: bannerState };
      return { ...prevState, ...newState };
    });
  };

  const bannerContextValues = useMemo(
    () => ({
      state,
      updateNotificationBannerState,
    }),
    [state],
  );

  return (
    <BannerContext.Provider value={bannerContextValues}>
      {children}
    </BannerContext.Provider>
  );
};

BannerProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export { BannerContext, BannerProvider };
