import { Script } from 'gatsby';

const DataLayerConfig = () => {
  return (
    <Script id="window-datalayer" key="window-datalayer">
      {`
        window.dataLayer = window.dataLayer || []
      `}
    </Script>
  );
};
export default DataLayerConfig;
