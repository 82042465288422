/* eslint-disable react/prop-types */
/* eslint-disable no-unused-expressions */
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import lunr from 'lunr';
import './static/fonts/fonts.css';
import { onAdaReady } from '@pcf-engineering/gatsby-plugin-pcs-ada-chatbot';

// eslint-disable-next-line import/no-relative-packages
import { enhanceLunr } from './plugins/gatsby-plugin-local-lunr/common';

export { wrapRootElement, wrapPageElement } from './gatsby-shared';

// * IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
export const onClientEntry = () => {
  enhanceLunr(lunr, ['fr']);
  if (typeof window.IntersectionObserver === `undefined`) {
    import(`intersection-observer`);
  }
  let browserUrlLang = 'en';
  if (typeof window !== 'undefined') {
    browserUrlLang = window.location.href.includes('/en/') ? 'en' : 'fr';
  }
  // Clear ADA meta fields on page load, per Timothy Lee
  onAdaReady(adaEmbed => {
    adaEmbed.setMetaFields({
      firstname: null,
      pcmaaccount: null,
      pcmcaccount: null,
      pcfcustid: null,
      goalaccount: null,
      language: browserUrlLang,
    });
  });
};

export const onPreRouteUpdate = ({ location }) => {
  // also setting metafields on page changes on route updates to ensure
  // language is carried along with it
  const browserUrlLang = location.href.includes('/en/') ? 'en' : 'fr';
  onAdaReady(adaEmbed => {
    adaEmbed.setMetaFields({
      firstname: null,
      pcmaaccount: null,
      pcmcaccount: null,
      pcfcustid: null,
      goalaccount: null,
      language: browserUrlLang,
    });
  });
};

export const onRouteUpdate = ({ location }) => {
  if (location.hash) {
    let { hash } = location;

    if (hash.slice(-1) === '/') {
      hash = hash.slice(0, -1);
    }

    setTimeout(() => {
      document.querySelector(hash).scrollIntoView();
    }, 0);
  }
};
