import { createContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

const SearchContext = createContext({
  lunrjs: {
    en: {
      index: null,
      store: null,
    },
    fr: { index: null, store: null },
  },
  updateLunrjs: () => {},
});

const SearchProvider = ({ children }) => {
  const [lunrjs, setLunrjs] = useState({
    en: {
      index: null,
      store: null,
    },
    fr: { index: null, store: null },
  });

  const updateLunrjs = newLunrState => {
    setLunrjs(prevState => {
      return { ...prevState, ...newLunrState };
    });
  };

  const searchContextValues = useMemo(
    () => ({ lunrjs, updateLunrjs }),
    [lunrjs],
  );

  return (
    <SearchContext.Provider value={searchContextValues}>
      {children}
    </SearchContext.Provider>
  );
};

SearchProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export { SearchProvider, SearchContext };
