import { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

const provinces = {
  'Province=AB': 'Alberta',
  'Province=ON': 'Ontario',
  'Province=BC': 'British Columbia',
  'Province=MB': 'Manitoba',
  'Province=NB': 'New Brunswick',
  'Province=NL': 'Newfoundland and Labrador',
  'Province=NS': 'Nova Scotia',
  'Province=PE': 'Prince Edward Island',
  'Province=QC': 'Quebec',
  'Province=SK': 'Saskatchewan',
  'Province=YT': 'Yukon',
  'Province=NT': 'Northwest Territories',
  'Province=NU': 'Nunavut',
};

const LocationContext = createContext(provinces['Province=ON']);

const Geolocation = ({ children }) => {
  const [location, setLocation] = useState(provinces['Province=ON']);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      axios
        .get('/en/')
        .then(response => {
          // * Check response status
          if (response.status !== 200) {
            console.warn(
              `Looks like there was a problem. Status Code: ${response.status}`,
            );
            return;
          }

          // * Split the response into Country, City, and Province. Looks like -> ["Country=CA", "City=BURLINGTON", "Province=ON"]
          const data = response.headers['x-geo-info'].split(',');
          const province = data[2].trim();

          setLocation(provinces[province]);
        })
        .catch(err => {
          console.warn('Fetch Error :-S', err);
        });
    }
  }, []);

  return (
    <LocationContext.Provider value={location}>
      {children}
    </LocationContext.Provider>
  );
};

Geolocation.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export { Geolocation, LocationContext };
