import { createContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

const defaultState = {
  state: {
    menuState: false,
    legalState: false,
    signInState: false,
  },
  updateMenuState: () => {},
  updateLegalState: () => {},
  updateSignInState: () => {},
  closeAllMenusOnEsc: () => {},
  resetState: () => {},
};

const Context = createContext(defaultState);

const Provider = ({ children }) => {
  const [state, setState] = useState({
    menuState: false,
    legalState: false,
  });

  const updateBladeState = bladeState => {
    setState(prevState => {
      return { ...prevState, ...bladeState };
    });
  };

  const updateMenuState = () => {
    setState(prevState => {
      const newState = { menuState: !prevState.menuState };
      return { ...prevState, ...newState };
    });
  };

  const updateLegalState = () => {
    setState(prevState => {
      const newState = { legalState: !prevState.legalState };
      return { ...prevState, ...newState };
    });
  };

  const updateSignInState = () => {
    setState(prevState => {
      const newState = { signInState: !prevState.signInState };
      return { ...prevState, ...newState };
    });
  };

  const resetState = () => {
    setState(prevState => {
      const newState = {};
      Object.keys(prevState).forEach(stateObj => {
        newState[stateObj] = false;
      });
      return { ...prevState, ...newState };
    });
  };

  const closeAllMenusOnEsc = e => {
    const event = e || window.event;
    if (event.key === 'Escape' || event.keyCode === 27) {
      resetState();
    }
  };

  const bladeContextValues = useMemo(
    () => ({
      state,
      updateBladeState,
      updateMenuState,
      updateLegalState,
      updateSignInState,
      closeAllMenusOnEsc,
      resetState,
    }),
    [state],
  );

  return (
    <Context.Provider value={bladeContextValues}>{children}</Context.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export { Context, Provider };
