import {
  createContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

const defaultState = {
  mobile: false,
};

let mediaQuery;
const ResponsiveContext = createContext(defaultState);

const ResponsiveProvider = ({ children }) => {
  const [mobile, setMobile] = useState(false);

  const matchMedia = useCallback((breakpoint = mediaQuery) => {
    if (breakpoint.matches) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      mediaQuery = window.matchMedia('(max-width: 767px)');
      mediaQuery.addListener(matchMedia);
      matchMedia();
    }
    return () => {
      mediaQuery.removeListener(matchMedia);
    };
  }, []);

  const contextValue = useMemo(
    () => ({
      mobile,
    }),
    [mobile],
  );

  return (
    <ResponsiveContext.Provider value={contextValue}>
      {children}
    </ResponsiveContext.Provider>
  );
};

ResponsiveProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export { ResponsiveContext, ResponsiveProvider };
