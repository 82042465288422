/* eslint-disable */
'use strict';

const enhanceLunr = (lunr, lngs) => {
  if (lngs.length) {
    require('lunr-languages/lunr.stemmer.support')(lunr);

    lngs.forEach(lang => {
      if (lang !== 'en') {
        try {
          if (lang === 'jp' || lang === 'ja') {
            require(`lunr-languages/tinyseg`)(lunr);
          }
          require(`lunr-languages/lunr.${lang}`)(lunr);
        } catch (e) {
          console.log(e);
        }
      }
    });
  }
};

module.exports = {
  enhanceLunr,
};
